(function() {
    'use strict';

    angular.module('uabFileManagement', [
        'ngCordova',
        'ionicAlert',
        'ionicRequest',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabPromiseManagement'
    ]);
})();
(function() {
    'use strict';

    angular.module('uabFileManagement').controller(
        'FileManagementController',
        FileManagementController
    );

    FileManagementController.$inject = [
        'FileManagementService'
    ];
    
    function FileManagementController(
        FileManagementService
    ) {
        var FileManagementController = this;

        FileManagementController.reset = reset;
        function reset() {
            FileManagementService.reset();
        }

        FileManagementController.reset();
    }
})();
(function() {
    'use strict';

    angular.module('uabFileManagement').service(
        'FileManagementService',
        FileManagementService
    );

    FileManagementService.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        'ErrorService',
        '$http',
        'PromiseManagementService',
        '$q',
        'RequestManagementService'
    ];

    function FileManagementService(
        DefaultVariableService,
        EnvironmentService,
        ErrorService,
        $http,
        PromiseManagementService,
        $q,
        RequestManagementService
    ) {
        var FileManagementService = this;

        FileManagementService.arrayBufferToBase64 = arrayBufferToBase64;
        function arrayBufferToBase64(buffer) {
            var binary = '';

            var bytes = new Uint8Array(buffer);

            var byteLength = bytes.byteLength;
            for (var i = 0; i < byteLength; i++) {
                binary += String.fromCharCode(bytes[i]);
            }

            return window.btoa(binary);
        }

        FileManagementService.findYoutubeId = findYoutubeId;
        function findYoutubeId(filePath) {
            filePath = DefaultVariableService.getString(filePath);

            var matches = filePath.match(/(\?|&)v=(_*[a-z]*[A-Z]*[0-9]*)*&{0}/);

            var youtubeId = false;
            if (matches && matches.length !== 0) {
                youtubeId = matches[0].replace('?v=', '');
                youtubeId = youtubeId.replace('&v=', '');
            }

            return youtubeId;
        }

        FileManagementService.getExternalStorageDirectories = getExternalStorageDirectories;
        function getExternalStorageDirectories() {
            var deferred = $q.defer();

            if (typeof CordovaExternalStoragePlugin !== 'undefined') {
                CordovaExternalStoragePlugin.getExternalStorageDirs(
                    function(sdCardDirs) {
                        deferred.resolve(
                            sdCardDirs
                        );
                    },
                    function() {
                        deferred.resolve(false);
                    }
                );
            } else {
                deferred.resolve(false);
            }

            return deferred.promise;
        }

        FileManagementService.getFile = getFile;
        function getFile(name) {
            if (typeof cordova !== 'undefined' && cordova.file) {
                return FileManagementService.getExternalStorageDirectories().then(
                    function(directories) {
                        var deferred = $q.defer();

                        var directory = 'file:///' + directories[0];

                        window.resolveLocalFileSystemURL(
                            directory,
                            function(dirEntry) {
                                dirEntry.getFile(
                                    name,
                                    {
                                        create: false,
                                        exclusive: false
                                    },
                                    function(fileEntry) {
                                        deferred.resolve(
                                            fileEntry.toURL()
                                        );
                                    },
                                    function() {
                                        deferred.resolve(false);
                                    }
                                );
                            },
                            function () {
                                return deferred.resolve(false);
                            }
                        );

                        return deferred.promise;
                    }
                );
            }

            return PromiseManagementService.generateSuccess(false);
        }

        FileManagementService.updateFilePath = updateFilePath;
        function updateFilePath(object, property) {
            property = DefaultVariableService.getString(
                property,
                'file_path'
            );

            if (object.hasOwnProperty(property)) {
                var imagePath = DefaultVariableService.get(
                    object[property],
                    false
                );

                if (imagePath && imagePath.indexOf('www.') === -1) {
                    if (imagePath.indexOf('base64') === -1) {
                        object['online_' + property] = object[property];

                        var url = RequestManagementService.buildUrl(imagePath);
                        url = RequestManagementService.finalizeUrl(url);

                        return $http({
                            method: 'GET',
                            url: url,
                            responseType: 'arraybuffer'
                        }).then(
                            function (response) {
                                return arrayBufferToBase64(response.data);
                            },
                            function () {
                                ErrorService.addWarning(
                                    'Could not download image (' + imagePath + ')'
                                );

                                return false;
                            }
                        ).then(
                            function (filePath) {
                                if (filePath) {
                                    object[property] = 'data:image/png;base64,' + filePath;
                                }

                                return object;
                            }
                        );
                    }
                }
            }

            return PromiseManagementService.generateSuccess(false);
        }

        FileManagementService.updateObjectImage = updateObjectImage;
        function updateObjectImage(object, image, property) {
            property = DefaultVariableService.getString(
                property,
                'image'
            );

            if (DefaultVariableService.isDefined(image)) {
                return FileManagementService.updateFilePath(image).then(
                    function(image) {
                        if (image) {
                            object[property] = image;
                        }

                        return object;
                    }
                );
            }

            return PromiseManagementService.generateSuccess(object);
        }

        FileManagementService.getExternalFile = getExternalFile;
        function getExternalFile(object, fileExtension) {
            var objectId = DefaultVariableService.getInteger(
                object.id,
                0
            );

            var fileName = objectId + '.' + fileExtension;

            return FileManagementService.getFile(fileName).then(
                function(filePath) {
                    if (!filePath) {
                        var url = DefaultVariableService.getString(
                            object.youtubeUrl
                        );

                        var youtubeId = FileManagementService.findYoutubeId(url);

                        if (youtubeId) {
                            object.youtubeId = youtubeId;

                            fileName = youtubeId + '.' + fileExtension;

                            return FileManagementService.getFile(fileName);
                        }
                    }

                    return filePath;
                }
            );
        }

        FileManagementService.getVideoFile = getVideoFile;
        function getVideoFile(object) {
            object.youtubeUrl = DefaultVariableService.getString(
                object.file_path
            );

            return FileManagementService.getExternalFile(
                object,
                FileManagementService.videoExtension
            ).then(
                function(filePath) {
                    object.file_path = filePath;

                    return object;
                }
            ).then(
                function() {
                    if (object.file_path) {
                        return FileManagementService.getCaptionsFile(object);
                    }

                    return object;
                }
            );
        }

        FileManagementService.getCaptionsFile = getCaptionsFile;
        function getCaptionsFile(object) {
            return FileManagementService.getExternalFile(
                object,
                FileManagementService.captionsExtension
            ).then(
                function(captionsFile) {
                    object.captionFile = captionsFile;

                    return object;
                }
            );
        }

        FileManagementService.updateImageFiles = updateImageFiles;
        function updateImageFiles(objects) {
            objects = DefaultVariableService.getArray(
                objects
            );

            var objectsLength = objects.length;

            var promises = [];

            for (var o = 0; o < objectsLength; o++) {
                var object = objects[o];

                var addedToPromise = false;
                for (var property in object) {
                    if (object.hasOwnProperty(property)) {
                        var promise = false;

                        var value = object[property];

                        if (DefaultVariableService.isDefined(value)) {
                            if (property === 'image' || property === 'content_image') {
                                promise = FileManagementService.updateObjectImage(object, value, property);
                            }
                        }

                        if (promise) {
                            addedToPromise = true;

                            promises.push(promise);
                        }
                    }
                }

                if (!addedToPromise) {
                    promises.push(
                        PromiseManagementService.generateSuccess(object)
                    );
                }
            }

            return PromiseManagementService.combinePromises(promises);
        }

        FileManagementService.updateFile = updateFile;
        function updateFile(model, object) {
            if (model === 'videos') {
                return FileManagementService.getVideoFile(object).then(
                    function(object) {
                        if (!object || !object.file_path) {
                            ErrorService.addWarning(
                                'Unable to find offline file: (' + object.id + ') ' + object.file_path
                            );
                        }

                        return object;
                    }
                );
            }

            return PromiseManagementService.generateSuccess(object);
        }

        FileManagementService.reset = reset;
        function reset() {
            FileManagementService.videoExtension = EnvironmentService.get('offlineVideoExtension');
            if (!FileManagementService.videoExtension) {
                FileManagementService.videoExtension = 'mp4';
            }

            FileManagementService.captionsExtension = EnvironmentService.get('offlineCaptionsExtension');
            if (!FileManagementService.captionsExtension) {
                FileManagementService.captionsExtension = 'vtt';
            }
        }

        FileManagementService.init = init;
        function init() {
            FileManagementService.reset();
        }

        FileManagementService.init();

        return FileManagementService;
    }
})();